import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://www.pinkswap.finance/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'https://www.pinkswap.finance/farms',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'https://pancakeswap.finance/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://pancakeswap.finance/lottery',
  //   status: {
  //     text: 'WIN',
  //     color: 'warning',
  //   },
  // },
  // {
  //   label: 'Collectibles',
  //   icon: 'NftIcon',
  //   href: 'https://pancakeswap.finance/nft',
  // },
  // {
  //   label: 'Team Battle',
  //   icon: 'TeamBattleIcon',
  //   href: 'https://pancakeswap.finance/competition',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://pancakeswap.finance/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://pancakeswap.finance/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://pancakeswap.finance/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://info.pinkswap.finance',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://info.pinkswap.finance',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://info.pinkswap.finance',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: 'https://pancakeswap.finance/ifo',
  // },
  {
    label: 'Contact',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Telegram',
        href: 'https://t.me/pinkswap',
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/pinkmoonfinance',
      },
      {
        label: 'Medium',
        href: 'https://medium.com/@pinkmoonfinance',
      },
      {
        label: 'Github',
        href: 'https://github.com/pinkmoonfinance',
      },
      {
        label: 'Reddit',
        href: 'https://www.reddit.com/r/PinkMoon/',
      },
    ],
  },
]

export default config
